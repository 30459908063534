import React, { Component } from "react";
import { connect } from "react-redux";
import { flamelink } from "../../config/flamelink";
import { BigHead } from "../../Components/BigHead/BigHead";
import { InfoSection } from "../../Components/InfoSection/InfoSection";
import Translate from "../../Components/Translate/Translate";
import { UpcomingEvents } from "../../Components/UpcomingEvents/UpcomingEvents";
import { Repertoire } from "../../Components/Repertoire/Repertoire";
import moment from "moment";
import { Controller, Scene } from "react-scrollmagic";

class Events extends Component {
  state = {
    showAllEvents: false
  };

  componentDidMount() {
    //window.scrollTo(0, 0);
  }

  render() {
    const { showAllEvents } = this.state;
    const { events } = this.props;

    console.log("events", events);

    return (
      <div className="page page--events events-page">
        <div className="page__inner">
          <BigHead title={<Translate word="events" />} theme="secondary" />
          <div className="container">
            {events.length < 1 && (
              <div className="event__not-found">
                No events at the moment. Check again later!
              </div>
            )}
            {events.length > 0 && (
              <div className="event__wrap">
                <div className="event__list">
                  {events
                    .slice(0, showAllEvents ? events.length : 5)
                    .map(event => (
                      <Controller>
                        <Scene
                          classToggle="animate"
                          indicators={false}
                          offset="-100"
                          triggerHook="0.7"
                        >
                          <div className="event__item">
                            <div className="event__left">
                              <div className="event__date">
                                {moment(event.date).format("DD-MM-YYYY")}
                              </div>
                              {event.timeFormated && (
                                <div className="event__time">
                                  {event.timeFormated}
                                </div>
                              )}
                              <div className="event__address">
                                {event.address}
                              </div>
                            </div>
                            <div className="event__right">
                              {event.additionalInfo && (
                                <div className="event__additional-text">
                                  {event.additionalInfo}
                                </div>
                              )}
                              <div className="event__title">
                                <Translate value={event.title} />
                              </div>
                              <div className="event__description">
                                <Translate value={event.description} />
                              </div>
                              {event.link && (
                                <a
                                  href={event.link}
                                  target="_blank"
                                  rel="noopener noreferrer"
                                  className="event__link"
                                >
                                  Find More
                                </a>
                              )}
                            </div>
                          </div>
                        </Scene>
                      </Controller>
                    ))}
                </div>
                {!showAllEvents && events.length > 5 && (
                  <div
                    className="more-btn"
                    onClick={() => this.setState({ showAllEvents: true })}
                  />
                )}
              </div>
            )}
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = ({ data }) => {
  return {
    events: data.events
  };
};

export default connect(
  mapStateToProps,
  null
)(Events);
