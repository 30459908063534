import React, { Component } from "react";
import { connect } from "react-redux";
import { flamelink } from "../../config/flamelink";
import { BigHead } from "../../Components/BigHead/BigHead";
import { InfoSection } from "../../Components/InfoSection/InfoSection";
import Translate from "../../Components/Translate/Translate";
import Portfolio from "../../Components/Portfolio/Portfolio";
import { UpcomingEvents } from "../../Components/UpcomingEvents/UpcomingEvents";

class Photography extends Component {
  state = {
    image: "",
    portrait: [],
    photojournalism: [],
    wedding: []
  };

  componentDidMount() {
    //window.scrollTo(0, 0);
    const imageId = this.props.photographyPage.image[0];
    this.getImgUrl(imageId);
    this.loadImages();
  }

  loadImages() {
    const { portrait, photojournalism, wedding } = this.props.photographyPage;
    console.log("this.props.photographyPage", this.props.photographyPage);
    portrait &&
      portrait.length > 0 &&
      portrait.map((image, index) => this.getImgUrls(image, index, "portrait"));

    photojournalism &&
      photojournalism.length > 0 &&
      photojournalism.map((image, index) =>
        this.getImgUrls(image, index, "photojournalism")
      );
    wedding &&
      wedding.length > 0 &&
      wedding.map((image, index) => this.getImgUrls(image, index, "wedding"));
  }

  getImgUrls(image, index, key) {
    flamelink.storage
      .getURL(image)
      .then(imageUrl => {
        let urls = this.state[key].slice();
        urls[index] = imageUrl;
        this.setState({ [key]: urls });
      })
      .catch(error => console.log(error));
  }

  getImgUrl(imageId) {
    flamelink.storage
      .getURL(imageId)
      .then(imageUrl => {
        this.setState({ image: imageUrl });
      })
      .catch(error => console.log(error));
  }

  render() {
    const { image, portrait, photojournalism, wedding } = this.state;
    const { events } = this.props;
    const {
      title,
      quote,
      author,
      speciality,
      text
    } = this.props.photographyPage;

    //console.log("ports", portraits);

    return (
      <div className="page page--photography photography-page">
        <div className="page__inner">
          <BigHead title={<Translate value={title} />} />
          <InfoSection
            title={<Translate value={title} />}
            quote={<Translate value={quote} />}
            author={<Translate value={author} />}
            authorSubtitle={<Translate value={speciality} />}
            text={<Translate value={text} />}
            image={image}
            secondTheme={true}
          />
          <Portfolio
            title={<Translate value={title} />}
            portrait={portrait}
            photojournalism={photojournalism}
            wedding={wedding}
          />
          <UpcomingEvents events={events.slice(0, 3)} />
        </div>
      </div>
    );
  }
}

const mapStateToProps = ({ data }) => {
  return {
    photographyPage: data.photographyPage,
    events: data.events
  };
};

export default connect(
  mapStateToProps,
  null
)(Photography);
