import React, { Component } from "react";
import { GalleryItem } from "../GalleryItem/GalleryItem";
import Lightbox from "@zhyabs1314/react-image-lightbox";
import "react-image-lightbox/style.css";
import { SectionTitle } from "../SectionTitle/SectionTitle";
import { GalleryTitle } from "../GalleryTitle/GalleryTitle";
import Translate from "../Translate/Translate";

class Portfolio extends Component {
  state = {
    photoIndex: 0,
    images: [],
    lightboxIsOpen: false,
    galleryIsOpen: false
  };

  openGallery(key) {
    this.setState({
      images: this.props[key].slice().reverse(),
      galleryIsOpen: true,
      galleryTitle: key
    });
  }

  closeGallery() {
    this.setState({
      galleryIsOpen: false
    });
  }

  openLightBox(index, key) {
    this.setState({
      photoIndex: index,
      lightboxIsOpen: true,
      ...(key && { images: this.props[key].slice().reverse() })
    });
  }

  closeLightbox() {
    this.setState({
      photoIndex: 0,
      lightboxIsOpen: false
    });
  }

  render() {
    const {
      photoIndex,
      images,
      lightboxIsOpen,
      galleryIsOpen,
      galleryTitle
    } = this.state;
    const { title, portrait, photojournalism, wedding, paintings } = this.props;
    return (
      <div className="section portfolio-section">
        <div className="container">
          <SectionTitle
            sectionTitle={<Translate word="portfolio" />}
            pageTitle={title}
          />
          {paintings && (
            <div className="gallery__list">
              <div className="row">
                {paintings
                  .slice()
                  .reverse()
                  .map((url, index) => (
                    <GalleryItem
                      onClick={() => this.openLightBox(index, "paintings")}
                      key={index}
                      url={url}
                    />
                  ))}
              </div>
            </div>
          )}
          {portrait && (
            <React.Fragment>
              <GalleryTitle
                title={<Translate word="portrait" />}
                onClick={() => this.openGallery("portrait")}
              />
              <div className="row">
                {portrait
                  .slice()
                  .reverse()
                  .slice(0, 4)
                  .map((url, index) => (
                    <GalleryItem
                      onClick={() => this.openGallery("portrait")}
                      key={index}
                      url={url}
                    />
                  ))}
              </div>
            </React.Fragment>
          )}
          {photojournalism && (
            <React.Fragment>
              <GalleryTitle
                title={<Translate word="photojournalism" />}
                onClick={() => this.openGallery("photojournalism")}
              />
              <div className="row">
                {photojournalism
                  .slice()
                  .reverse()
                  .slice(0, 4)
                  .map((url, index) => (
                    <GalleryItem
                      onClick={() => this.openGallery("photojournalism")}
                      key={index}
                      url={url}
                    />
                  ))}
              </div>
            </React.Fragment>
          )}
          {wedding && (
            <React.Fragment>
              <GalleryTitle
                title={<Translate word="wedding" />}
                onClick={() => this.openGallery("wedding")}
              />
              <div className="row">
                {wedding
                  .slice()
                  .reverse()
                  .slice(0, 4)
                  .map((url, index) => (
                    <GalleryItem
                      onClick={() => this.openGallery("wedding")}
                      key={index}
                      url={url}
                    />
                  ))}
              </div>
            </React.Fragment>
          )}
          {lightboxIsOpen && (
            <Lightbox
              animationDisabled={true}
              enableZoom={false}
              mainSrc={images[photoIndex]}
              nextSrc={images[(photoIndex + 1) % images.length]}
              prevSrc={images[(photoIndex + images.length - 1) % images.length]}
              onCloseRequest={() => this.closeLightbox()}
              onMovePrevRequest={() =>
                this.setState({
                  photoIndex: (photoIndex + images.length - 1) % images.length
                })
              }
              onMoveNextRequest={() =>
                this.setState({
                  photoIndex: (photoIndex + 1) % images.length
                })
              }
            />
          )}
        </div>

        <div className={`${galleryIsOpen ? "active " : ""} gallery__modal`}>
          <div className="container">
            <div
              className="gallery__heading"
              onClick={() => this.closeGallery()}
            >
              <Translate word={galleryTitle} />
              <div className="gallery__back" />
            </div>
            <div className="gallery__list">
              <div className="row">
                {images.map((url, index) => (
                  <GalleryItem
                    onClick={() => this.openLightBox(index)}
                    key={index}
                    url={url}
                  />
                ))}
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default Portfolio;
