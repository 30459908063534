import React, { Component } from "react";
import { connect } from "react-redux";
import { flamelink } from "../../config/flamelink";
import { BigHead } from "../../Components/BigHead/BigHead";
import { InfoSection } from "../../Components/InfoSection/InfoSection";
import Translate from "../../Components/Translate/Translate";
import { UpcomingEvents } from "../../Components/UpcomingEvents/UpcomingEvents";
import Repertoire from "../../Components/Repertoire/Repertoire";

class Piano extends Component {
  state = {
    image: ""
  };

  componentDidMount() {
    //window.scrollTo(0, 0);
    const imageId = this.props.pianoPage.image[0];
    this.getImgUrl(imageId);
  }

  getImgUrl(imageId) {
    flamelink.storage
      .getURL(imageId)
      .then(imageUrl => {
        this.setState({ image: imageUrl });
      })
      .catch(error => console.log(error));
  }

  render() {
    const { image } = this.state;
    const { events, repertoire } = this.props;
    const { title, quote, author, speciality, text } = this.props.pianoPage;

    return (
      <div className="page page--piano piano-page">
        <div className="page__inner">
          <BigHead title={<Translate value={title} />} />
          <InfoSection
            title={<Translate value={title} />}
            quote={<Translate value={quote} />}
            author={<Translate value={author} />}
            authorSubtitle={<Translate value={speciality} />}
            text={<Translate value={text} />}
            image={image}
          />
          <Repertoire
            repertoire={repertoire}
            title={<Translate value={title} />}
          />
          <UpcomingEvents events={events.slice(0, 3)} />
        </div>
      </div>
    );
  }
}

const mapStateToProps = ({ data }) => {
  return {
    pianoPage: data.pianoPage,
    events: data.events,
    repertoire: data.repertoire
  };
};

export default connect(
  mapStateToProps,
  null
)(Piano);
