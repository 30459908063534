import * as firebase from "firebase";

import { FirebaseConfig } from "../config/keys";
export const firebaseApp = firebase.initializeApp(FirebaseConfig);

const databaseRef = firebase.database().ref();

export const mainRef = databaseRef.child(
  "flamelink/environments/production/content/"
);
