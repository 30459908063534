import React, { Component } from "react";
import { connect } from "react-redux";
import { flamelink } from "../../config/flamelink";
import { BigHead } from "../../Components/BigHead/BigHead";
import { InfoSection } from "../../Components/InfoSection/InfoSection";
import Translate from "../../Components/Translate/Translate";
import Portfolio from "../../Components/Portfolio/Portfolio";
import { UpcomingEvents } from "../../Components/UpcomingEvents/UpcomingEvents";
import axios from "axios";
import { Controller, Scene } from "react-scrollmagic";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.min.css";

class Contacts extends Component {
  state = {
    topic: "",
    userEmail: "",
    message: "",
    topicError: "",
    emailError: "",
    messageError: ""
  };

  componentDidMount() {
    //window.scrollTo(0, 0);
  }

  validateForm(e) {
    const { topic, userEmail, message } = this.state;

    this.setState({
      topicError: "",
      emailError: "",
      messageError: ""
    });

    let valid = true;

    if (topic === "") {
      this.setState({ topicError: " " });
      valid = false;
    }

    if (userEmail === "") {
      this.setState({ emailError: " " });
      valid = false;
    }

    // if (message === "") {
    //   this.setState({ messageError: " " });
    //   valid = false;
    // }

    if (!valid) return;
    const that = this;

    const params = new URLSearchParams();
    params.append("topic", topic);
    params.append("userEmail", userEmail);
    params.append("message", message);

    // axios.post("mail.php", {
    //   topic,
    //   userEmail,
    //   message
    // });
    axios({
      method: "post",
      url: "mail.php",
      data: params
    })
      .then(response => {
        toast.success("The mail has been sent successfully!", {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          background: "#000 !important"
        });
        that.setState({
          topic: "",
          userEmail: "",
          message: "",
          topicError: "",
          emailError: "",
          messageError: ""
        });
        console.log("success", response);
      })
      .catch(error => {
        toast.error("Something went wrong please try again!", {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true
        });
        console.log("fail", error);
      });
  }

  render() {
    const {
      topic,
      userEmail,
      message,
      topicError,
      emailError,
      messageError
    } = this.state;
    const { title, name, email, phone, address } = this.props.contactPage;
    return (
      <div className="page page--contacts contacts-page">
        <ToastContainer />
        <div className="page__inner">
          <BigHead darkMode={true} title={<Translate value={title} />} />
          <div className="contacts__background">
            <div className="container overflow-hidden">
              <div className="row">
                <div className="offset-md-6 col-md-5">
                  <Controller>
                    <Scene
                      classToggle="animate"
                      indicators={false}
                      offset="-100"
                      triggerHook="0.7"
                    >
                      <form action="mail.php" method="post" className="form">
                        <div
                          className={`${
                            topicError ? "input--error " : ""
                          }input`}
                        >
                          <input
                            name="topic"
                            type="text"
                            placeholder="topic"
                            value={topic}
                            onChange={e =>
                              this.setState({ topic: e.target.value })
                            }
                          />
                          {/* {topicError && (
                          <div className="input__error">{topicError}</div>
                        )} */}
                        </div>
                        <div
                          className={`${
                            emailError ? "input--error " : ""
                          }input`}
                        >
                          <input
                            name="email"
                            type="email"
                            placeholder="Email"
                            value={userEmail}
                            onChange={e =>
                              this.setState({ userEmail: e.target.value })
                            }
                          />
                          {/* {emailError && (
                          <div className="input__error">{emailError}</div>
                        )} */}
                        </div>
                        <div
                          className={`${
                            messageError ? "text-area--error " : ""
                          }text-area`}
                        >
                          <textarea
                            name="message"
                            id=""
                            cols="30"
                            rows="10"
                            placeholder="Message"
                            value={message}
                            onChange={e =>
                              this.setState({ message: e.target.value })
                            }
                          />
                          <div
                            className="button"
                            onClick={e => this.validateForm(e)}
                          >
                            <Translate word="send" />
                          </div>
                        </div>
                        {/* {messageError && (
                        <div className="text-area__error">{messageError}</div>
                      )} */}
                      </form>
                    </Scene>
                  </Controller>
                  <Controller>
                    <Scene
                      classToggle="animate"
                      indicators={false}
                      offset="-100"
                      triggerHook="0.7"
                    >
                      <div className="contacts">
                        <div className="contacts__item contacts__item--name">
                          {name}
                        </div>
                        <div className="contacts__item">{email}</div>
                        <div className="contacts__item">{phone}</div>
                        <div className="contacts__item">{address}</div>
                      </div>
                    </Scene>
                  </Controller>
                  {/* <div className="row">
                    <div className="col-md-12">
                      <Controller>
                        <Scene
                          classToggle="animate"
                          indicators={false}
                          offset="-100"
                          triggerHook="0.9"
                        >
                          <div
                            className="button"
                            onClick={e => this.validateForm(e)}
                          >
                            Send
                          </div>
                        </Scene>
                      </Controller>
                    </div>
                  </div> */}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = ({ data }) => {
  return {
    contactPage: data.contactPage
  };
};

export default connect(
  mapStateToProps,
  null
)(Contacts);
