import React, { Component } from "react";
import { NavLink, withRouter } from "react-router-dom";
import { connect } from "react-redux";
import Translate from "../../Components/Translate/Translate";

class Home extends Component {
  render() {
    const { homeBackground } = this.props;

    return (
      <div className="page page--home home-page">
        <div className="page__inner">
          <div className="hero__wrap">
            <div className="hero__logo"></div>
            <ul className="nav__list">
              <li className="nav__item">
                <NavLink activeClassName="active" to="/piano" exact>
                  <Translate word="piano" />
                </NavLink>
              </li>
              <li className="nav__item">
                <NavLink activeClassName="active" to="/photography" exact>
                  <Translate word="photography" />
                </NavLink>
              </li>
              <li className="nav__item">
                <NavLink activeClassName="active" to="/painting" exact>
                  <Translate word="painting" />
                </NavLink>
              </li>
            </ul>
          </div>
        </div>
        <div
          className="hero__background"
          style={{
            background: `url('${homeBackground}') no-repeat center center / cover`
          }}
        />
      </div>
    );
  }
}

const mapStateToProps = ({ data }) => {
  return {
    homePage: data.homePage
  };
};

export default connect(
  mapStateToProps,
  null
)(Home);
