import React from "react";
import { Scene, Controller } from "react-scrollmagic";

export const SectionTitle = ({ sectionTitle, pageTitle }) => (
  <div className="row">
    <div className="col-md-12">
      <Controller>
        <Scene
          classToggle="animate"
          indicators={false}
          offset="-100"
          triggerHook="0.8"
        >
          <div className="section__title">
            <div className="section__title-text-one">{pageTitle}</div>
            <div className="section__title-text-two">{sectionTitle}</div>
          </div>
        </Scene>
      </Controller>
    </div>
  </div>
);
