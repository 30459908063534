import moment from "moment";
import { FETCH_MAIN_DATA } from "../actions/types";
import { objectToArray } from "../helpers/functions";

const initialState = {
  translations: {},
  events: [],
  repertoire: [],
  filters: [],
  homePage: {},
  pianoPage: {},
  photographyPage: {},
  paintingPage: {},
  contactPage: {},
  mediaPage: {},
  isMainDataFetching: true
};

export default (state = initialState, action) => {
  switch (action.type) {
    case FETCH_MAIN_DATA:
      const events = Array.from(objectToArray(action.payload.events["en-US"]))
        .sort((a, b) => {
          return new Date(a.date).getTime() - new Date(b.date).getTime();
        })
        .map(event => ({
          ...event,
          dateFormated: moment(event.date).format("DD-MM-YYYY"),
          timeFormated: event.time
        }))
        .filter(
          event =>
            new Date(event.date).getTime() + 86400000 > new Date().getTime()
        );

      const repertoire = Array.from(
        objectToArray(action.payload.repertoire["en-US"].repertoire)
      );

      let filters = [];

      repertoire.map(repertoire => {
        const categoryFromRepertoire = repertoire.filter.category;
        const subcategoryFromRepertoire = repertoire.filter.subcategory;
        const categoryExists = filters.some(
          filter => filter.category === categoryFromRepertoire
        );
        const filterIndex = filters.findIndex(
          filter => filter.category === categoryFromRepertoire
        );

        if (categoryExists) {
          if (
            subcategoryFromRepertoire &&
            !filters[filterIndex].subcategories.some(
              subcategory => subcategory === subcategoryFromRepertoire
            )
          ) {
            filters[filterIndex].subcategories.push(subcategoryFromRepertoire);
          }
        } else {
          filters.push({
            category: categoryFromRepertoire,
            subcategories: subcategoryFromRepertoire
              ? [subcategoryFromRepertoire]
              : []
          });
        }
      });

      filters = filters
        // .map(filter => ({
        //   ...filter,
        //   subcategories: filter.subcategories.sort((a, b) => a.localeCompare(b))
        // }))
        .map(filter => {
          if (filter.category === "Chamber music works") {
            let newSubCats = filter.subcategories.filter(filter => {
              if (
                filter === "With violin" ||
                filter === "With viola" ||
                filter === "With cello" ||
                filter === "With flute" ||
                filter === "With clarinet" ||
                filter === "With oboe" ||
                filter === "With bassoon" ||
                filter === "With horn" ||
                filter === "With saxophone" ||
                filter === "With harp" ||
                filter === "With percussion" ||
                filter === "Trio" ||
                filter === "Quartet" ||
                filter === "Various ensembles/compilations"
              ) {
                return false;
              } else {
                return true;
              }
            });
            //.sort((a, b) => a.localeCompare(b));
            newSubCats.push(
              "With violin",
              "With viola",
              "With cello",
              "With flute",
              "With clarinet",
              "With oboe",
              "With bassoon",
              "With horn",
              "With saxophone",
              "With harp",
              "With percussion",
              "Trio",
              "Quartet",
              "Various ensembles/compilations"
            );
            return {
              ...filter,
              subcategories: newSubCats
            };
          } else if (filter.category === "Piano works") {
            let newSubCats = filter.subcategories
              .filter(filter => {
                if (filter === "Solo") return false;
                if (filter === "Duo") return false;
                if (filter === "Arrangements ") return false;
                return true;
              })
              .sort((a, b) => a.localeCompare(b));
            newSubCats.push("Solo", "Duo", "Arrangements ");
            return {
              ...filter,
              subcategories: newSubCats
            };
          } else {
            return filter;
          }
        });
      console.log("filters", filters);

      return {
        ...state,
        translations: action.payload.translations["en-US"],
        events,
        repertoire,
        filters,
        homePage: action.payload.homePage["en-US"],
        pianoPage: action.payload.pianoPage["en-US"],
        photographyPage: action.payload.photographyPage["en-US"],
        paintingPage: action.payload.paintingPage["en-US"],
        contactPage: action.payload.contactPage["en-US"],
        mediaPage: action.payload.mediaPage["en-US"],
        isMainDataFetching: action.isFetching
      };
    default:
      return state;
  }
};
