import { mainRef } from "../config/firebase";
import { FETCH_MAIN_DATA } from "./types";

export const fetchMainData = () => async dispatch => {
  mainRef.on("value", snapshot => {
    dispatch({
      type: FETCH_MAIN_DATA,
      payload: snapshot.val(),
      isFetching: false
    });
  });
};
