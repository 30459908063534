import React from "react";
import instagramIcon from "../../assets/img/instagram.svg";
import facebookIcon from "../../assets/img/facebook.svg";
import mailIcon from "../../assets/img/mail.svg";
import Translate from "../Translate/Translate";

export const Footer = () => (
  <div className="footer">
    <div className="container">
      <div className="footer__social">
        <a
          href="https://www.instagram.com/akvilesileikaite/"
          target="_blank"
          rel="noopener noreferrer"
          className="footer__social-link"
        >
          <img height="17px" width="17px" src={instagramIcon} alt="Instagram" />
        </a>
        <a
          href="https://www.facebook.com/sileikaite.akvile/"
          target="_blank"
          rel="noopener noreferrer"
          className="footer__social-link"
        >
          <img height="17px" width="17px" src={facebookIcon} alt="Facebook" />
        </a>
        <a href="mailto:akvile@sileikaite.com" className="footer__social-link">
          <img height="17px" width="22px" src={mailIcon} alt="Mail" />
        </a>
      </div>
      <div className="footer__copy">
        <div className="footer__logo" />
        <div className="footer__copy-text">
          {new Date().getFullYear()} &copy;{" "}
          <Translate word="allRightsReserved" />
        </div>
      </div>
      <div className="footer__author">
        <div className="footer__author-text">
          <Translate word="craftedBy" />
        </div>
        <a
          //href="http://cukrusagency.lt/"
          href="javascript:void(0)"
          //target="_blank"
          //rel="noopener noreferrer"
          className="cukrus-logo"
        />
        <a
          //href="http://cukrusagency.lt/"
          href="javascript:void(0)"
          //target="_blank"
          //rel="noopener noreferrer"
          className="cukrus-logo-active"
        />
      </div>
    </div>
  </div>
);
