import React, { Component } from "react";
import { connect } from "react-redux";
import ReactDropdown from "react-dropdown";
import "react-dropdown/style.css";
import { Controller, Scene } from "react-scrollmagic";
import { SectionTitle } from "../SectionTitle/SectionTitle";
import Translate from "../Translate/Translate";

class Repertoire extends Component {
  state = {
    categoryOptions: this.props.filters
      .filter(filter => filter.category)
      .map(filter => filter.category),
    subcategoryOptions: this.props.filters[0].subcategories,
    category: this.props.filters[0].category,
    subcategory: this.props.filters[0].subcategories[0],
    showSubcategoryDropdown: true
  };

  setCategory(category) {
    const { filters } = this.props;
    const filterIndex = filters.findIndex(
      filter => filter.category === category
    );
    const subcategoryOptions = filters[filterIndex].subcategories;

    this.setState({
      category,
      subcategory: subcategoryOptions[0],
      subcategoryOptions,
      showSubcategoryDropdown: subcategoryOptions.length > 0
    });
  }

  setSubcategory(subcategory) {
    this.setState({ subcategory });
  }

  render() {
    const {
      categoryOptions,
      subcategoryOptions,
      category,
      subcategory,
      showSubcategoryDropdown
    } = this.state;

    const { title, repertoire } = this.props;

    let filteredRepertoire = repertoire
      .filter(
        repertoire =>
          repertoire.filter.category === category &&
          (!showSubcategoryDropdown ||
            repertoire.filter.subcategory === subcategory)
      )
      .sort((a, b) => a.composer.localeCompare(b.composer));

    console.log("categoryOptions", categoryOptions);

    return (
      <div className="section repertoire-section">
        <div className="container">
          <SectionTitle
            sectionTitle={<Translate word="repertoire" />}
            pageTitle={title}
          />
          <div className="row">
            <div className="col-md-12">
              <Controller>
                <Scene
                  classToggle="animate"
                  indicators={false}
                  offset="-100"
                  triggerHook="0.8"
                >
                  <div className="repertoire__filters">
                    <ReactDropdown
                      onChange={option => this.setCategory(option.value)}
                      value={category}
                      options={categoryOptions}
                      placeholder="Select category"
                    />
                    {showSubcategoryDropdown && (
                      <ReactDropdown
                        onChange={option => this.setSubcategory(option.value)}
                        value={subcategory}
                        options={subcategoryOptions}
                        placeholder="Select subcategory"
                      />
                    )}
                  </div>
                </Scene>
              </Controller>
              <Controller>
                <Scene
                  classToggle="animate"
                  indicators={false}
                  offset="44"
                  triggerHook="0.9"
                >
                  <div className="repertoire__heading">
                    <Translate word="composer" />
                  </div>
                </Scene>
              </Controller>
              <Controller>
                {filteredRepertoire &&
                  filteredRepertoire.map(repertoire => (
                    <Scene
                      classToggle="animate"
                      indicators={false}
                      offset="0"
                      triggerHook="0.9"
                    >
                      <div className="repertoire">
                        <div className="repertoire__composer">
                          {repertoire.composer}
                        </div>
                        <div
                          className="repertoire__description"
                          dangerouslySetInnerHTML={{
                            __html: repertoire.description
                          }}
                        ></div>
                      </div>
                    </Scene>
                  ))}
              </Controller>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = ({ data }) => {
  return {
    filters: data.filters
  };
};

export default connect(
  mapStateToProps,
  null
)(Repertoire);
