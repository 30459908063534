import React, { Component } from "react";
import { animateScroll as scroll } from "react-scroll";
import { Controller, Scene } from "react-scrollmagic";
import { Tween, Timeline } from "react-gsap";

export class BigHead extends Component {
  state = {
    animate: false
  };
  componentDidMount() {
    setTimeout(() => {
      this.setState({ animate: true });
    }, 1000);
  }

  render() {
    const { animate } = this.state;
    const { title, darkMode, theme } = this.props;

    return (
      <React.Fragment>
        {theme === "secondary" && (
          <Controller>
            <Scene
              duration="100%"
              triggerHook="0"
              offset="1"
              indicators={false}
            >
              <Timeline>
                <Tween
                  position=""
                  from={{
                    yPercent: 0
                  }}
                  to={{
                    yPercent: 10
                  }}
                >
                  <div
                    className={`${darkMode ? "big-head--dark-mode " : ""} ${
                      theme ? "big-head--secondary " : ""
                    }big-head`}
                  >
                    <div className="container">
                      {/* <div
                        className={`${
                          animate ? "animate " : ""
                        }big-head__line-1`}
                      />
                      <div
                        className={`${
                          animate ? "animate " : ""
                        }big-head__line-2`}
                      />
                      <div
                        className={`${
                          animate ? "animate " : ""
                        }big-head__line-3`}
                      />
                      <div
                        className={`${
                          animate ? "animate " : ""
                        }big-head__line-4`}
                      />
                      <div
                        className={`${
                          animate ? "animate " : ""
                        }big-head__line-5`}
                      /> */}
                      <Controller>
                        <Scene
                          duration="120%"
                          triggerHook="0"
                          offset="1"
                          indicators={false}
                        >
                          <Timeline>
                            <Tween
                              position=""
                              from={{
                                yPercent: 0
                              }}
                              to={{
                                yPercent: 150
                              }}
                            >
                              <div
                                className={`${
                                  animate ? "animate " : ""
                                }big-head__text-two`}
                              >
                                {title}
                              </div>
                            </Tween>
                          </Timeline>
                        </Scene>
                      </Controller>
                    </div>
                  </div>
                </Tween>
              </Timeline>
            </Scene>
          </Controller>
        )}
        {theme !== "secondary" && (
          <div
            className={`${darkMode ? "big-head--dark-mode " : ""} ${
              theme ? "big-head--secondary " : ""
            }big-head`}
          >
            <div className="container">
              <div className={`${animate ? "animate " : ""}big-head__line-1`} />
              <div className={`${animate ? "animate " : ""}big-head__line-2`} />
              <div className={`${animate ? "animate " : ""}big-head__line-3`} />
              <div className={`${animate ? "animate " : ""}big-head__line-4`} />
              <div className={`${animate ? "animate " : ""}big-head__line-5`} />
              <Controller>
                <Scene
                  duration="120%"
                  triggerHook="0"
                  offset="1"
                  indicators={false}
                >
                  <Timeline>
                    <Tween
                      position=""
                      from={{
                        yPercent: 0
                      }}
                      to={{
                        yPercent: 120
                      }}
                    >
                      <div
                        className={`${
                          animate ? "animate " : ""
                        }big-head__text-one`}
                      >
                        {title}
                      </div>
                    </Tween>
                  </Timeline>
                </Scene>
              </Controller>
              <Controller>
                <Scene
                  duration="120%"
                  triggerHook="0"
                  offset="1"
                  indicators={false}
                >
                  <Timeline>
                    <Tween
                      position=""
                      from={{
                        yPercent: 0
                      }}
                      to={{
                        yPercent: 150
                      }}
                    >
                      <div
                        className={`${
                          animate ? "animate " : ""
                        }big-head__text-two`}
                      >
                        {title}
                      </div>
                    </Tween>
                  </Timeline>
                </Scene>
              </Controller>
              {/* <div
                className="big-head__arrow"
                onClick={() => scroll.scrollTo(window.innerHeight)}
              /> */}
            </div>
          </div>
        )}
      </React.Fragment>
    );
  }
}
export default BigHead;
