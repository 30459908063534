import React, { Component } from "react";
import { connect } from "react-redux";
import { flamelink } from "../../config/flamelink";
import { BigHead } from "../../Components/BigHead/BigHead";
import Translate from "../../Components/Translate/Translate";
import { UpcomingEvents } from "../../Components/UpcomingEvents/UpcomingEvents";
import moment from "moment";
import ReactPlayer from "react-player";
import { Controller, Scene } from "react-scrollmagic";
import { GalleryTitle } from "../../Components/GalleryTitle/GalleryTitle";
import SlickSlider from "react-slick";

const slickSettings = {
  dots: true,
  infinite: true,
  speed: 500,
  slidesToShow: 2,
  slidesToScroll: 1,
  arrows: false,
  responsive: [
    {
      breakpoint: 575,
      settings: {
        slidesToShow: 1,
        slidesToScroll: 1,
        infinite: true,
        dots: true
      }
    }
  ]
};

class Media extends Component {
  state = {
    showAllArticles: false,
    articles: this.props.mediaPage.articles
  };

  componentDidMount() {
    this.loadImages();
    //window.scrollTo(0, 0);
  }

  loadImages() {
    const { articles } = this.props.mediaPage;
    console.log("articles", articles);
    articles.map((article, index) => this.getImgUrls(article.image[0], index));
  }

  getImgUrls(image, index, key) {
    flamelink.storage
      .getURL(image)
      .then(imageUrl => {
        let articles = this.state.articles.slice();
        articles[index] = { ...articles[index], imageUrl };
        this.setState({ articles });
      })
      .catch(error => console.log(error));
  }

  render() {
    const { showAllArticles, articles } = this.state;
    const { events } = this.props;
    const { title, videos } = this.props.mediaPage;

    //console.log("ports", portraits);

    return (
      <div className="page page--media media-page">
        <div className="page__inner">
          {" "}
          <BigHead title={<Translate value={title} />} />
          <div className="container">
            {videos && (
              <div className="video__wrap">
                <GalleryTitle title={<Translate word="videos" />} />
                <SlickSlider {...slickSettings}>
                  {videos.map(video => (
                    <Controller>
                      <Scene
                        classToggle="animate"
                        indicators={false}
                        offset="-200"
                        triggerHook="0.85"
                      >
                        <div className="video__container">
                          <div className="video">
                            <ReactPlayer
                              url={video.url}
                              light={true}
                              width="100%"
                              height="100%"
                              controls={true}
                            />
                          </div>
                        </div>
                      </Scene>
                    </Controller>
                  ))}
                </SlickSlider>
              </div>
            )}
            {articles && (
              <div className="article__wrap">
                <GalleryTitle title={<Translate word="articles" />} />
                <div className="row">
                  {articles
                    .slice(0, showAllArticles ? articles.length : 2)
                    .map(article => (
                      <div className="col-lg-6 col-md-12">
                        <Controller>
                          <Scene
                            classToggle="animate"
                            indicators={false}
                            offset="-200"
                            triggerHook="0.85"
                          >
                            <div className="article">
                              <div
                                className="article__image"
                                style={{
                                  background: `url('${article.imageUrl}') no-repeat center center / cover`
                                }}
                              />
                              <div className="article__details">
                                <div className="article__title">
                                  <Translate value={article.title} />
                                </div>
                                {article.date && (
                                  <div className="article__date">
                                    {moment(article.date).format("DD-MM-YYYY")}
                                  </div>
                                )}
                                <div className="article__description">
                                  <Translate value={article.description} />
                                </div>
                                {article.link && (
                                  <a
                                    className="article__more"
                                    target="_blank"
                                    rel="noopener noreferrer"
                                    href={article.link}
                                  >
                                    <Translate word="readMore" />
                                  </a>
                                )}
                              </div>
                            </div>
                          </Scene>
                        </Controller>
                      </div>
                    ))}
                </div>
                {!showAllArticles && (
                  <div
                    className="more-btn"
                    onClick={() => this.setState({ showAllArticles: true })}
                  />
                )}
              </div>
            )}
          </div>
          <UpcomingEvents events={events.slice(0, 3)} />
        </div>
      </div>
    );
  }
}

const mapStateToProps = ({ data }) => {
  return {
    mediaPage: data.mediaPage,
    events: data.events
  };
};

export default connect(
  mapStateToProps,
  null
)(Media);
