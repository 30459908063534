import React, { Component } from "react";
import { withTranslation } from "react-i18next";
import { compose } from "recompose";
import { connect } from "react-redux";

class Translate extends Component {
  render() {
    const { value, word, translations, returnString } = this.props;
    const lang = this.props.i18n.language;

    if (returnString) return <>0</>;

    return (
      <React.Fragment>
        {word && (
          <span
            className="wysiwyg"
            dangerouslySetInnerHTML={{
              __html:
                translations[word] && translations[word][lang]
                  ? translations[word][lang]
                  : ""
            }}
          />
        )}
        {!word && (
          <span
            className="wysiwyg"
            dangerouslySetInnerHTML={{
              __html: value && value[lang] ? value[lang] : ""
            }}
          />
        )}
      </React.Fragment>
    );
  }
}

const mapStateToProps = ({ data }) => {
  return {
    translations: data.translations
  };
};

export default compose(
  connect(
    mapStateToProps,
    null
  ),
  withTranslation()
)(Translate);
