import React, { Component } from "react";
import { NavLink } from "react-router-dom";
import { withTranslation } from "react-i18next";
import Translate from "../Translate/Translate";
import Lottie from "react-lottie";

const menuItems = [
  "piano",
  "photography",
  "painting",
  "events",
  "media",
  "contacts"
];

const lottieOptions = {
  loop: false,
  autoplay: true,
  animationData: require("../../assets/loader.json"),
  rendererSettings: {
    preserveAspectRatio: "xMidYMid slice"
  }
};

class Menu extends Component {
  state = {
    isMenuOpen: false,
    showLogoAnimation: false
  };

  openMenu() {
    this.setState({ isMenuOpen: true, showLogoAnimation: true });
  }

  closeMenu() {
    this.setState({ isMenuOpen: false });
    setTimeout(() => {
      this.setState({ showLogoAnimation: false });
    }, 500);
  }

  renderLink(item) {
    return (
      <NavLink
        className="menu__item"
        activeClassName="active"
        to={`/${item}`}
        onClick={() => this.closeMenu()}
      >
        <Translate word={item} />
      </NavLink>
    );
  }

  changeLang(lang) {
    this.props.i18n.changeLanguage(lang);
  }

  render() {
    const { isMenuOpen, showLogoAnimation } = this.state;
    const lang = this.props.i18n.language;

    return (
      <React.Fragment>
        <div className="menu__trigger" onClick={() => this.openMenu()}>
          <div className="menu__trigger-text">Menu</div>
        </div>

        <div className={`${isMenuOpen ? "open " : ""}menu__box`}>
          <div className="menu__wrap">
            <NavLink to="/" onClick={() => this.closeMenu()}>
              {showLogoAnimation && (
                <div className="menu__logo">
                  <Lottie options={lottieOptions} height={80} width={65} />
                </div>
              )}
            </NavLink>
            <div className="lang-selector">
              <div
                className={`${
                  lang === "en" ? "active " : ""
                }lang-selector__item`}
                onClick={() => this.changeLang("en")}
              >
                EN
              </div>
              <div
                className={`${
                  lang === "de" ? "active " : ""
                }lang-selector__item`}
                onClick={() => this.changeLang("de")}
              >
                DE
              </div>
            </div>
            <div className="menu__list">
              {menuItems.map(item => this.renderLink(item))}
            </div>
          </div>
        </div>

        <div
          className={`${isMenuOpen ? "open " : ""}menu__backdrop`}
          onClick={() => this.closeMenu()}
        />
      </React.Fragment>
    );
  }
}

export default withTranslation()(Menu);
