import React, { Component } from "react";
import {
  Route,
  BrowserRouter as Router,
  Switch,
  Redirect,
  withRouter
} from "react-router-dom";
import { Transition, TransitionGroup } from "react-transition-group";
import { Footer } from "./Components/Footer/Footer";
import Home from "./Containers/Home/Home";
import Piano from "./Containers/Piano/Piano";
import Photography from "./Containers/Photography/Photography";
import Painting from "./Containers/Painting/Painting";
import Contacts from "./Containers/Contacts/Contacts";
import Events from "./Containers/Events/Events";
import Media from "./Containers/Media/Media";
import { play, exit } from "./timelines";

class Container extends Component {
  render() {
    const { location, homeBackground } = this.props;
    return (
      <div className="content">
        <TransitionGroup component={null}>
          <Transition
            key={location.key}
            appear={true}
            onEnter={(node, appears) => play(location.pathname, node, appears)}
            onExit={(node, appears) => exit(node, appears)}
            timeout={{ enter: 350, exit: 150 }}
          >
            <section className="route-section">
              <Switch location={location}>
                <Route
                  exact
                  path="/"
                  render={props => (
                    <Home {...props} homeBackground={homeBackground} />
                  )}
                />
                <Route exact path="/piano" component={Piano} />
                <Route exact path="/photography" component={Photography} />
                <Route exact path="/painting" component={Painting} />
                <Route exact path="/contacts" component={Contacts} />
                <Route exact path="/events" component={Events} />
                <Route exact path="/media" component={Media} />
                <Route
                  render={props => (
                    <Home {...props} homeBackground={homeBackground} />
                  )}
                />
                <Redirect to="/" />
              </Switch>
              {location.pathname !== "/" && <Footer />}
            </section>
          </Transition>
        </TransitionGroup>
      </div>
    );
  }
}

export default withRouter(Container);
