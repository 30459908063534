import React from "react";
import { Controller, Scene } from "react-scrollmagic";
import { Tween, Timeline } from "react-gsap";
import { SectionTitle } from "../SectionTitle/SectionTitle";
import Translate from "../Translate/Translate";

export const InfoSection = ({
  title,
  quote,
  author,
  authorSubtitle,
  text,
  image,
  secondTheme
}) => (
  <div
    className={`${
      secondTheme ? "info-section--second-theme" : ""
    } section info-section`}
  >
    <div className="container">
      <SectionTitle
        sectionTitle={<Translate word="about" />}
        pageTitle={title}
      />
      <Controller>
        <Scene
          classToggle="animate"
          indicators={false}
          offset="200"
          triggerHook="1"
        >
          <div className="row info-section__wrap">
            <div className="col-md-7">
              <div className="quote">
                <div className="quote__symbol" />
                <div className="quote__text">{quote}</div>
                <div className="quote__author">
                  -{` `}
                  {author}
                </div>
                <div className="quote__author-subtitle">{authorSubtitle}</div>
              </div>
              <div className="info-section__text">{text}</div>
            </div>
            <div className="col-md-5">
              <div className="info-section__image-wrap">
                <Controller>
                  <Scene duration="200%" triggerHook="onEnter">
                    <Timeline>
                      <Tween
                        position="0"
                        from={{
                          yPercent: -5
                        }}
                        to={{
                          yPercent: 5
                        }}
                      >
                        <div className="info-section__image-container">
                          <div className="info-section__image-container-inner">
                            <div
                              className="info-section__image"
                              style={{
                                background: `url('${image}') no-repeat center center / cover`
                              }}
                            />
                          </div>
                        </div>
                      </Tween>
                      <Tween
                        position="0"
                        from={{
                          yPercent: 20
                        }}
                        to={{
                          yPercent: -5
                        }}
                      >
                        <div className="info-section__image-artefact" />
                      </Tween>
                    </Timeline>
                  </Scene>
                </Controller>
              </div>
            </div>
          </div>
        </Scene>
      </Controller>
    </div>
  </div>
);
