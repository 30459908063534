import React from "react";
import Translate from "../Translate/Translate";
import { Controller, Scene } from "react-scrollmagic";
import { NavLink } from "react-router-dom";

export const UpcomingEvents = ({ events }) => (
  <div className="upcoming">
    {events.length > 0 && (
      <div className="container">
        <Controller>
          <Scene
            classToggle="animate"
            indicators={false}
            offset="100"
            triggerHook="0.85"
          >
            <div className="upcoming__heading">
              <Translate word="upcomingEvents" />
            </div>
          </Scene>
        </Controller>
        <div className="row">
          <Controller>
            {events.map(event => (
              <Scene
                classToggle="animate"
                indicators={false}
                offset="100"
                triggerHook="0.85"
              >
                <div className="col-md-4">
                  <NavLink to="/events" className="upcoming__link">
                    <div className="upcoming__item">
                      <div className="upcoming__title">
                        <Translate value={event.title} />
                      </div>
                      <div className="upcoming__subtitle">
                        <Translate value={event.description} />
                      </div>
                      <div className="upcoming__address">{event.address}</div>
                      <div className="upcoming__date">
                        {event.dateFormated}{" "}
                        {event.timeFormated && `| ${event.timeFormated}`}
                      </div>
                    </div>
                  </NavLink>
                </div>
              </Scene>
            ))}
          </Controller>
        </div>
      </div>
    )}
  </div>
);
