import React, { Component } from "react";
import { connect } from "react-redux";
import { flamelink } from "../../config/flamelink";
import { BigHead } from "../../Components/BigHead/BigHead";
import { InfoSection } from "../../Components/InfoSection/InfoSection";
import Translate from "../../Components/Translate/Translate";
import Portfolio from "../../Components/Portfolio/Portfolio";
import { UpcomingEvents } from "../../Components/UpcomingEvents/UpcomingEvents";

class Painting extends Component {
  state = {
    image: "",
    paintings: []
  };

  componentDidMount() {
    //window.scrollTo(0, 0);
    const imageId = this.props.paintingPage.image[0];
    this.getImgUrl(imageId);
    this.loadImages();
  }

  loadImages() {
    const { paintings } = this.props.paintingPage;
    paintings.map((image, index) => this.getImgUrls(image, index, "paintings"));
  }

  getImgUrls(image, index, key) {
    flamelink.storage
      .getURL(image)
      .then(imageUrl => {
        let urls = this.state[key].slice();
        urls[index] = imageUrl;
        this.setState({ [key]: urls });
      })
      .catch(error => console.log(error));
  }

  getImgUrl(imageId) {
    flamelink.storage
      .getURL(imageId)
      .then(imageUrl => {
        this.setState({ image: imageUrl });
      })
      .catch(error => console.log(error));
  }

  render() {
    const { image, paintings } = this.state;
    const { events } = this.props;
    const { title, quote, author, speciality, text } = this.props.paintingPage;

    //console.log("ports", portraits);

    return (
      <div className="page page--painting painting-page">
        <div className="page__inner">
          <BigHead title={<Translate value={title} />} />
          <InfoSection
            title={<Translate value={title} />}
            quote={<Translate value={quote} />}
            author={<Translate value={author} />}
            authorSubtitle={<Translate value={speciality} />}
            text={<Translate value={text} />}
            image={image}
          />
          <Portfolio
            title={<Translate value={title} />}
            paintings={paintings}
          />
          <UpcomingEvents events={events.slice(0, 3)} />
        </div>
      </div>
    );
  }
}

const mapStateToProps = ({ data }) => {
  return {
    paintingPage: data.paintingPage,
    events: data.events
  };
};

export default connect(
  mapStateToProps,
  null
)(Painting);
