import React, { Component } from "react";
import { BrowserRouter as Router } from "react-router-dom";
import Container from "./Container";
import Menu from "./Components/Menu/Menu";
import { fetchMainData } from "./actions";
import { connect } from "react-redux";
import compose from "recompose/compose";
import Lottie from "react-lottie";
import { flamelink } from "./config/flamelink";
import Axios from "axios";

const lottieOptions = {
  loop: false,
  autoplay: true,
  animationData: require("./assets/loader.json"),
  rendererSettings: {
    preserveAspectRatio: "xMidYMid slice"
  }
};

class App extends Component {
  state = {
    loaderDirection: 1,
    isFakeFetching: true,
    showBackDrop: true,
    image: ""
  };

  componentDidMount() {
    this.props.fetchMainData();
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.isFetching !== this.props.isFetching) {
      const imageId = nextProps.homePage.background[0];
      this.getImgUrl(imageId);
    }
  }

  getImgUrl(imageId) {
    flamelink.storage
      .getURL(imageId)
      .then(imageUrl => {
        this.getBase64(imageUrl);
      })
      .catch(error => console.log(error));
  }

  getBase64(url) {
    Axios.get(url, {
      responseType: "arraybuffer"
    }).then(response => {
      console.log("response", response);
      this.setState({
        image:
          "data:image/jpeg;base64," +
          new Buffer(response.data, "binary").toString("base64"),
        isFakeFetching: false
      });
      setTimeout(() => {
        this.setState({ showBackDrop: false });
      }, 2000);
    });
  }

  render() {
    const { isFakeFetching, image, showBackDrop } = this.state;
    return (
      <Router>
        {(isFakeFetching || showBackDrop) && (
          <div className={`loader__wrap ${!isFakeFetching ? " fade-out" : ""}`}>
            <Lottie
              options={lottieOptions}
              height={80}
              width={120}
              direction={this.state.loaderDirection}
              eventListeners={[
                {
                  eventName: "complete",
                  callback: () =>
                    this.setState({
                      loaderDirection: this.state.loaderDirection * -1
                    })
                }
              ]}
            />
          </div>
        )}
        {!isFakeFetching && (
          <div className="app">
            <Menu />
            <Container homeBackground={image} />
          </div>
        )}
      </Router>
    );
  }
}

const mapDispatchToProps = {
  fetchMainData
};

const mapStateToProps = ({ data }) => {
  const { isMainDataFetching } = data;
  return {
    homePage: data.homePage,
    isFetching: isMainDataFetching
  };
};

export default compose(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )
)(App);
