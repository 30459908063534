import React from "react";
import { Scene, Controller } from "react-scrollmagic";

export const GalleryItem = ({ url, onClick }) => (
  <div className="col-md-3 gallery__item-wrap">
    <Controller>
      <Scene
        classToggle="animate"
        indicators={false}
        offset="-100"
        triggerHook="1"
      >
        <div className="gallery__item-box">
          <div
            onClick={onClick}
            className="gallery__item"
            style={{
              background: `url('${url}') no-repeat center center / cover`
            }}
          />
        </div>
      </Scene>
    </Controller>
  </div>
);
