import React from "react";
import { Scene, Controller } from "react-scrollmagic";

export const GalleryTitle = ({ title, onClick }) => (
  <div className="row">
    <div className="col-md-3">
      <Controller>
        <Scene
          classToggle="animate"
          indicators={false}
          offset="44"
          triggerHook="0.9"
        >
          <div
            className={`gallery__title ${onClick ? "clickable" : ""}`}
            onClick={onClick ? onClick : null}
          >
            <span className="gallery__title-text">{title}</span>
          </div>
        </Scene>
      </Controller>
    </div>
  </div>
);
